import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { TabContent, TabPane, Button } from "reactstrap";
import styled from "styled-components";
import { FaChevronLeft } from "react-icons/fa";
import Login from "./Login";
import Register from "./Register";
import AccountErrorBoundry from "./AccountErrorBoundry";

const LoginOrRegister = ({
    children,
    startingTab = "login",
    showForms = true,
    authCallback,
}) => {
    const isLoggedIn = useSelector((state) => state.root.isLoggedIn);
    const hasPW = useSelector((state) => state.root.userSession.hasPW);
    const verified = useSelector((state) => state.root.userSession.verified);
    const firstName = useSelector((state) => state.root.userSession.firstName);
    const isMobile = useSelector((state) => state.root.isMobile);
    const [isRegistering, setIsRegistering] = useState(
        !verified && !hasPW && firstName ? true : false
    );
    const [activeTab, setActiveTab] = useState(
        isLoggedIn && isRegistering ? "register" : startingTab
    );

    const toggle = (tab) => {
        if (authCallback) authCallback();
        if (activeTab !== tab) setActiveTab(tab);
    };

    useEffect(() => {
        if (isLoggedIn && !isRegistering && authCallback) authCallback(false);
    }, [isLoggedIn, isRegistering, authCallback]);

    return (
        <AccountErrorBoundry>
            {isLoggedIn && !isRegistering ? (
                children
            ) : (
                <>
                    <div className="d-flex justify-content-around align-items-center mb-2">
                        {showForms && authCallback && (
                            <FaChevronLeft
                                className="d-lg-none"
                                onClick={() => authCallback(false)}
                            />
                        )}
                        <div className="flex-fill ps-2 pe-1">
                            <UserButtons
                                className={`w-100 ${
                                    isMobile
                                        ? "border px-2"
                                        : `border-0 rounded-0 py-2 ${
                                              activeTab === "login"
                                                  ? "border-bottom border-primary"
                                                  : ""
                                          }`
                                }`}
                                color={isMobile ? "light" : "text"}
                                onClick={() => {
                                    toggle("login");
                                }}
                                usehoverborder={`${!isMobile}`}
                            >
                                Sign in
                            </UserButtons>
                        </div>
                        <div className="flex-fill ps-1 pe-2">
                            <UserButtons
                                className={`w-100 ${
                                    isMobile
                                        ? "border px-2"
                                        : `border-0 rounded-0 py-2 ${
                                              activeTab === "register"
                                                  ? "border-bottom border-primary"
                                                  : ""
                                          }`
                                }`}
                                color={isMobile ? "light" : "text"}
                                onClick={() => {
                                    toggle("register");
                                }}
                                usehoverborder={`${!isMobile}`}
                            >
                                Register
                            </UserButtons>
                        </div>
                    </div>
                    {showForms && (
                        <TabContent
                            className="flex-grow-1"
                            activeTab={activeTab}
                        >
                            <TabPane className="h-100" tabId="login">
                                {activeTab === "login" && (
                                    <Login
                                        isRegistering={isRegistering}
                                        setIsRegistering={setIsRegistering}
                                    />
                                )}
                            </TabPane>
                            <TabPane className="h-100" tabId="register">
                                {activeTab === "register" && (
                                    <Register
                                        setIsRegistering={setIsRegistering}
                                    />
                                )}
                            </TabPane>
                        </TabContent>
                    )}
                </>
            )}
        </AccountErrorBoundry>
    );
};

const UserButtons = styled(Button)`
    &:hover {
        cursor: pointer;
        border-bottom: ${({ usehoverborder }) =>
            usehoverborder ? "1px solid !important" : ""};
    }
`;

export default LoginOrRegister;
