import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";

const PageScroller = ({ children }) => {
    const { pathname } = useLocation();
    const routeParams = useParams();
    useEffect(() => {
        if (typeof window !== "undefined" && window !== null) {
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 3);
        }
        // eslint-disable-next-line
    }, [routeParams, pathname]);
    return <>{children}</>;
};

export default PageScroller;
