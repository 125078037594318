import React from "react";
import { useSelector } from "react-redux";
import { getCdnBasePath } from "../../support/helpers";
import Hero from "../Pages/Home/Hero";

const HeroErrorWrapper = ({ children, ...props }) => {
    const { nicheDomain2ndLevel } =
        useSelector((state) => state.root.styling) || {};
    const touchDevice = useSelector((state) => state.root.isMobile);

    return (
        <div {...props}>
            <Hero
                path={`${getCdnBasePath(nicheDomain2ndLevel)}/hero-${
                    touchDevice ? "mobile" : "desktop"
                }.jpg`}
                className="w-100"
            >
                <div className="d-flex align-items-center position-relative w-100 justify-content-center">
                    <div
                        className={`bg-white p-2 my-5 text-dark bg-opacity-75 rounded w-50 ${
                            touchDevice ? "w-75" : ""
                        }`}
                    >
                        {children}
                    </div>
                </div>
            </Hero>
        </div>
    );
};

export default HeroErrorWrapper;
