import React from "react";
import { nFormatter } from "../../support/helpers";
import { stringify } from "query-string";

export const parseSearchMatches = ({
    supplementalInfo,
    listingsPreview,
    nicheLabel = "Markets",
    searchTerm,
}) => {
    const {
        matchesState,
        matchesNicheItem,
        matchesZipcode,
        matchesCity,
        matchesMls,
        matchesDevelopment,
    } = supplementalInfo;
    const options = [];
    // ListingsPreview will be part of MLS at some point. Leaving for now but this will get merged into the matchesMls response.
    if (listingsPreview && listingsPreview.length > 0) {
        const listings = listingsPreview[0]?.listingsPreview || [];
        if (listings.length > 0) {
            const listingOptions = [];
            listings.forEach((listing) => {
                const label = (
                    <div className="d-flex">
                        <div className="me-2">
                            <img
                                style={{ height: "75px", width: "auto" }}
                                src={listing.images[0].uriThumbnail}
                                alt={listing.dIListingPreview.nicheItem}
                            />
                        </div>
                        <div>
                            <strong
                                dangerouslySetInnerHTML={{
                                    __html: listing.dIListingPreview.address,
                                }}
                            />
                            <div>{listing.dIListingPreview.nicheItem}</div>
                        </div>
                    </div>
                );
                listingOptions.push({
                    value: listing,
                    label,
                    type: "listing",
                    searchTerm,
                });
            });
            options.push({ label: "Listings", options: listingOptions });
        }
    }
    if (matchesMls) {
        const mlsptions = [];
        matchesMls.forEach((match) => {
            mlsptions.push({
                value: match,
                label: match.value,
                type: "mls",
                searchTerm,
            });
        });
        options.push({ label: "Properties", options: mlsptions });
    }
    if (matchesState) {
        const stateOptions = [];
        matchesState.forEach((match) => {
            stateOptions.push({
                value: match,
                label: match.value,
                type: "state",
                searchTerm,
            });
        });
        options.push({ label: "State", options: stateOptions });
    }
    if (matchesNicheItem) {
        const nicheOptions = [];
        matchesNicheItem.forEach((match) => {
            nicheOptions.push({
                value: match,
                label: match.value,
                type: "niche",
                searchTerm,
            });
        });
        options.push({ label: nicheLabel, options: nicheOptions });
    }
    if (matchesZipcode) {
        const zipCodeOptions = [];
        matchesZipcode.forEach((match) => {
            // Faking a region so that it will trigger a region search
            match.more.region = {
                state_code: match.more.stateID,
                more: {
                    distance: 20,
                    search: match.value,
                    zip: match.more?.zip,
                },
            };
            zipCodeOptions.push({
                value: match,
                label: match.value,
                type: "zip",
                searchTerm,
            });
        });
        options.push({ label: "Postal Codes", options: zipCodeOptions });
    }
    if (matchesCity) {
        const cityOptions = [];
        matchesCity.forEach((match) => {
            cityOptions.push({
                value: match,
                label: match.value,
                type: "city",
                searchTerm,
            });
        });
        options.push({ label: "Cities", options: cityOptions });
    }
    if (matchesDevelopment) {
        const developmentOptions = [];
        matchesDevelopment.development.forEach((match) => {
            const label =
                matchesDevelopment.development.length > 1
                    ? `${match.name} (${match.stateCode})`
                    : match.name;
            developmentOptions.push({
                value: `/${match.stateUri}/${match.areaUri}`,
                label,
                type: "development",
                searchTerm,
            });
        });
        options.push({ label: "Developments", options: developmentOptions });
    }

    return options;
};

export const buildPriceRangeLabel = (min, max) => {
    if (min && max) {
        return `${nFormatter(min)} - ${nFormatter(max)}`;
    } else if (min && !max) {
        return `${nFormatter(min)}+`;
    } else if (!min && max) {
        return `Up to ${typeof max === "number" ? nFormatter(max) : max}`;
    } else {
        return "Price";
    }
};

export const buildMegaLabel = (filters, megaItems, stateId) => {
    try {
        const megaStateList = [stateId];
        if (filters && megaItems) {
            filters.forEach((id) => {
                const megaItem = megaItems.find(
                    (item) => item[0].more.id === parseInt(id)
                );
                megaStateList.push(megaItem[0].more.stateCode);
            });
        }
        return `Spans: ${megaStateList.toString().replaceAll(",", ", ")}`;
    } catch {
        return `Spans: ${stateId}`;
    }
};

export const sortQueryString = (queryString, pathname = "") => {
    if (!queryString) return;
    const splittableString = queryString.startsWith("?")
        ? queryString.substring(1)
        : queryString;
    const parts = splittableString.split("&");
    parts.sort((a, b) => {
        return a.localeCompare(b);
    });
    return `${pathname}?${parts.join("&")}`;
};

/**
 * Builds the query key used to store search data in the redux state
 * @param {object} locationQuery - locationQueryModel or similar
 * @param {object} searchQuery - searchQueryModel or similar
 * @returns {object} containing keys
 */
export const getQueryKeys = (locationQuery, searchQuery, pathname) => ({
    locationQueryKey: sortQueryString(stringify(locationQuery)),
    searchQueryKey: sortQueryString(stringify(searchQuery)),
    resultsQueryKey: sortQueryString(
        stringify({ ...searchQuery, ...locationQuery }),
        pathname
    ),
    gisQueryKey: sortQueryString(
        stringify({
            ...searchQuery,
            ...locationQuery,
            page: undefined,
            sort_order: undefined,
        }),
        pathname
    ),
});

/**
 * Builds query object from request params and query model.
 * @param {object} requestQuery - route query params
 * @param {object} model - query model used for
 * @returns {object} of query prams that are in the provided model
 */
export const buildQueryObjectFromRequest = (requestQuery, model) => {
    const queryObject = {};
    Object.keys(requestQuery).forEach((routeItem) => {
        if (Object.keys(model).includes(routeItem)) {
            queryObject[routeItem] = requestQuery[routeItem];
        }
    });
    return queryObject;
};
