import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PubSub from "pubsub-js";
import { useListings } from "../../hooks/useListings";
import { useTrackEvent } from "../../hooks/useTrackEvent";
import {
    isEmptyObject,
    isOffMarketPage,
    filterNicheItemToIds,
    resolveLocationStateItem,
} from "../../support/helpers";
import { getQueryKeys } from "./helpers";
import {
    resetSearch,
    setSearchLocationByQueryParams,
    setSearchResultsByQueryParams,
    setGisResultsByQueryParams,
    setSearchHistory,
    clearSearchHistory,
    setIsFetchingGis,
    setIsFetching,
} from "../../reducers/searchReducer";
import {
    useQueryParams,
    useQueryParam,
    StringParam,
    NumberParam,
    DelimitedNumericArrayParam,
} from "use-query-params";
import { useSearchLocations } from "../../hooks/locations";
import { useNacLocation } from "../../hooks/useNacLocation";
import { matchRoute } from "../../routes";

export const locationQueryModel = {
    search: StringParam,
    zip: StringParam, //using a NumberParam here strips off the leading 0
    range: NumberParam,
    region: StringParam,
};
export const searchQueryModel = {
    price_min: StringParam,
    price_max: StringParam,
    waterfront: NumberParam,
    bedrooms: NumberParam,
    bathrooms: NumberParam,
    property_type: StringParam,
    mega: DelimitedNumericArrayParam,
    sort_order: StringParam,
    page: NumberParam,
};

const SearchProvider = ({ children }) => {
    const dispatch = useDispatch();
    const {
        pathname,
        search: initialSearchString,
        state: {
            stateId: state_code = undefined,
            nicheItemId,
            developmentId: developmentID,
            agentId,
        } = {},
    } = useNacLocation();
    const niche_item_id =
        nicheItemId || resolveLocationStateItem("nicheItemId");
    const [fullQuery, setFullQuery] = useQueryParams({
        ...locationQueryModel,
        ...searchQueryModel,
    });
    const [locationQuery, setLocationQuery] =
        useQueryParams(locationQueryModel);
    const [searchQuery, setSearchQuery] = useQueryParams(searchQueryModel);
    const [isSaved, setIsSaved] = useQueryParam("isSaved", StringParam);
    const [previousSearchQuery, setPreviousSearchQuery] = useState();
    const { locationQueryKey, resultsQueryKey, gisQueryKey } = getQueryKeys(
        locationQuery,
        searchQuery,
        pathname
    );
    const locationData = useSelector(
        (state) => state.search.location[locationQueryKey]
    );
    const resultsData = useSelector(
        (state) => state.search.results?.[resultsQueryKey]
    );
    const searchHistory = useSelector((state) => state.search.searchHistory);
    const { execute: executeLocationSearch } = useSearchLocations();
    const isStatePage = matchRoute(pathname) === "state";
    const defaultRange = useSelector((state) => state.search.defaultRange);
    const { execute: executeListingPreview } = useListings();
    const { execute: executeGis } = useListings(true);
    const { trackEvent, trackGTM } = useTrackEvent();
    const [pageNumber, setPageNumber] = useState(
        resultsData?.page_number || null
    );
    const isOffMarket = isOffMarketPage(pathname);
    const isFetching = useRef(false);
    const isFirstRender = useRef(true);

    useEffect(() => {
        const sub = PubSub.subscribe(
            "RESET_SEARCH",
            (subName, params = false) => {
                resetSearch(dispatch);
                // Using the object models to reset the query params
                let resetObject = {};
                if (!params?.filtersOnly) {
                    Object.keys(locationQueryModel).forEach(
                        (key) => (resetObject[key] = null)
                    );
                }
                Object.keys(searchQueryModel).forEach(
                    (key) => (resetObject[key] = null)
                );
                setFullQuery({ ...resetObject, mapView: null, mapZoom: null });
                dispatch(clearSearchHistory());
            }
        );
        return () => PubSub.unsubscribe(sub);
        // eslint-disable-next-line
    }, [isStatePage]);

    useEffect(() => {
        setPreviousSearchQuery(searchQuery);
    }, [searchQuery]);

    const getListings = async (filters, page) => {
        dispatch(setIsFetching("listings"));
        const payload = { filters, page };
        if (isOffMarket) payload.offmarket = isOffMarket;
        const data =
            filters?.niche_item_ids?.length === 0 && !filters.agent_id
                ? {
                      page_count: 0,
                      total_count: 0,
                      page_number: 1,
                      per_page: 20,
                      results: [],
                  }
                : await executeListingPreview(payload);
        let location = resultsQueryKey;
        if (
            !page &&
            data?.page_number == 1 &&
            resultsQueryKey &&
            resultsQueryKey.includes("page")
        ) {
            location = gisQueryKey;
        }
        dispatch(
            setSearchResultsByQueryParams({
                location,
                data,
                nicheItemId: niche_item_id,
            })
        );
        isFetching.current = false;
        dispatch(setIsFetching());
    };

    // page and sort order are not needed in a GIS call
    const getGis = async ({ page, sort_order, state_code, ...filters }) => {
        dispatch(setIsFetchingGis(true));
        // Attempt to get cached filters
        let { nicheItemsNotFoundInCache, ...data } =
            filters?.niche_item_ids?.length === 0 && !filters.agent_id
                ? {
                      filters: {
                          niche_item_ids: [],
                          state_code: locationData?.myFilterStateCode,
                      },
                      results: [],
                      total_count: 0,
                  }
                : await executeGis({ filters }, false);
        // If no GIS data is found, just get it the old way (fallback)
        // This will most likely happen when the cache is not built.
        if (
            data.total_count > 0 &&
            Array.isArray(nicheItemsNotFoundInCache) &&
            nicheItemsNotFoundInCache.length > 0
        ) {
            const notFoundData = await executeGis(
                {
                    filters: {
                        ...filters,
                        niche_item_ids: nicheItemsNotFoundInCache,
                    },
                },
                true
            );
            data = {
                ...data,
                results: [
                    ...(data?.results || []),
                    ...(notFoundData?.results || []),
                ],
            };
        }
        dispatch(
            setGisResultsByQueryParams({
                location: gisQueryKey,
                data,
                nicheItemId: niche_item_id,
            })
        );
        isFetching.current = false;
        dispatch(setIsFetchingGis(false));
    };

    const trackLocationSearch = (data) => {
        trackGTM({
            event: `search`,
            action: "click",
            type: "Location Search",
            category: "user_action",
            value: locationQuery.search || locationQuery.zip,
            range: data?.myFilterDistanceMiles,
            zipcode: data?.zipcode,
            latitude: data?.latitude,
            longitude: data?.longitude,
        });
        trackEvent("USER-SEARCH", {
            SOURCE_INFO: {
                // GA
                eventAction: "search",
                eventLabel: "Location Search",
                eventValue: locationQuery.search || locationQuery.zip,
                // FB
                content_category: "Location Search",
                search_string: locationQuery.search || locationQuery.zip,
            },
        });
    };

    const buildFilters = (nicheItems) => {
        let filters = { state_code, ...searchQuery };
        // Removing any filters that are undefined
        Object.keys(filters).forEach(
            (key) => filters[key] === undefined && delete filters[key]
        );

        if (agentId) filters.agent_id = agentId;
        if (developmentID) filters.developmentID = developmentID;
        if (niche_item_id) {
            filters.niche_item_id = niche_item_id;
        } else if (nicheItems) {
            const hasSearchItem = Object.values(searchQuery).find(
                (value) => value !== undefined
            );
            if (hasSearchItem === undefined) filters = {};
            const niche_item_ids = filterNicheItemToIds(nicheItems);
            filters.niche_item_ids = niche_item_ids;
        } else if (!nicheItems) {
            filters.niche_item_ids = [];
        }
        return filters;
    };

    useEffect(() => {
        let fetchData;
        if (!isFetching.current) {
            if (locationQueryKey && locationQuery?.zip && !locationData) {
                const { search, zip, range } = locationQuery;
                fetchData = async () => {
                    isFetching.current = true;
                    if (!search)
                        setLocationQuery({ ...locationQuery, search: zip });
                    const response = await executeLocationSearch({
                        state_code,
                        search_box: zip,
                        distance_miles: range || defaultRange,
                        match_exact: 1,
                    });
                    dispatch(
                        setSearchLocationByQueryParams({
                            location: locationQueryKey,
                            data: response,
                        })
                    );
                    trackLocationSearch(response);

                    const { page, ...filters } = buildFilters(
                        response?.matchesNicheItems?.diNicheItems
                    );
                    await getListings(filters, page);
                    getGis(filters);
                };
            } else if (searchQuery.page && pageNumber !== searchQuery.page) {
                fetchData = async () => {
                    isFetching.current = true;
                    const filters = buildFilters(
                        locationData?.matchesNicheItems?.diNicheItems
                    );
                    setPageNumber(searchQuery.page);
                    if (isFirstRender.current) {
                        await getListings(filters, searchQuery.page);
                        if (!isOffMarket) getGis(filters);
                    } else {
                        await getListings(filters, searchQuery.page);
                    }
                    isFetching.current = false;
                };
            } else if (
                (Object.values(searchQuery).some((val) => val) &&
                    !resultsData) ||
                (locationQuery?.zip && !resultsData)
            ) {
                fetchData = async () => {
                    isFetching.current = true;
                    const filters = buildFilters(
                        locationData?.matchesNicheItems?.diNicheItems
                    );
                    setSearchQuery({ ...searchQuery, page: undefined });
                    setPageNumber(1);
                    await getListings(filters);

                    // We want to prevent the GIS call if the user only selects a sort order
                    // UNELESS the are coming from a saved seach
                    if (
                        !isOffMarket &&
                        (isSaved ||
                            previousSearchQuery?.sort_order ===
                                searchQuery?.sort_order)
                    ) {
                        getGis(filters, true); // isFetching set to false in GIS function
                    } else {
                        isFetching.current = false;
                    }
                    if (isSaved) setIsSaved(); //resetting the saved state
                };
                // resetting the STATE page number if there is a missmatch. This can happen
                // if we already have search data and a sort_order change is triggered.
            } else if (pageNumber !== searchQuery.page) {
                setPageNumber(searchQuery.page || 1);
            }
        }
        if (fetchData) fetchData();
        if (isFirstRender) isFirstRender.current = false; // This is here for local development. Rendering SSR should skip this.
    }, [locationQuery, searchQuery, locationData, isOffMarket, resultsData]);

    // On first load, if we need to retrieve GIS data we go get it
    useEffect(() => {
        let fetchData;
        if (window.shouldFetchGis === true) {
            delete window.shouldFetchGis;
            fetchData = async () => {
                isFetching.current = true;
                const filters = buildFilters(
                    locationData?.matchesNicheItems?.diNicheItems
                );
                await getGis(filters, true);
                isFetching.current = false;
            };
        }
        if (fetchData) fetchData();
    }, []);

    // Setting Search by state
    useEffect(() => {
        if (initialSearchString) {
            dispatch(
                setSearchHistory({
                    page: pathname,
                    query: resultsQueryKey,
                    nicheItemId: niche_item_id,
                    developmentId: developmentID,
                })
            );
        }
    }, [initialSearchString]);

    useEffect(() => {
        if (!isEmptyObject(searchHistory)) {
            dispatch(clearSearchHistory());
        }
    }, [state_code]);

    useEffect(() => {
        if (!isEmptyObject(searchHistory)) {
            dispatch(clearSearchHistory("nicheItem"));
        }
    }, [niche_item_id]);

    useEffect(() => {
        if (!isEmptyObject(searchHistory)) {
            dispatch(clearSearchHistory("development"));
        }
    }, [developmentID]);

    return children;
};

export default SearchProvider;
