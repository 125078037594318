import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, ModalBody } from "reactstrap";
import PubSub from "pubsub-js";
import Logout from "./Logout";
import LoginOrRegister from "./LoginOrRegister";
import Summary from "./Summary";
import ExistingVerify from "./ExistingVerify";
import Drawer from "../Drawer";
import useToggle from "../../hooks/useToggle";
import { FaUser } from "react-icons/fa";

const Account = ({ className }) => {
    const firstName = useSelector((state) => state.root.userSession.firstName);
    const isLoggedIn = useSelector((state) => state.root.isLoggedIn);
    const [loginCallback, setLoginCallback] = useState();
    const [isOpen, toggleIsOpen] = useToggle(false);

    useEffect(() => {
        const sub = PubSub.subscribe(
            "TOGGLE_ACCOUNT_DRAWER",
            (msg, callback) => {
                if (callback) setLoginCallback(callback);
                toggleIsOpen();
            }
        );
        return () => PubSub.unsubscribe(sub);
        // eslint-disable-next-line
    }, [toggleIsOpen]);

    return (
        <>
            <Button
                color="link"
                className={className}
                onClick={() => toggleIsOpen()}
            >
                {isLoggedIn ? <FaUser /> : "Sign in"}
            </Button>
            <Drawer
                isOpen={isOpen}
                toggle={() => toggleIsOpen()}
                headerText={firstName}
            >
                <ModalBody>
                    <LoginOrRegister
                        authCallback={(val = true) => {
                            if (typeof loginCallback === "function") {
                                loginCallback(true);
                                setLoginCallback();
                            }
                        }}
                    >
                        <ExistingVerify>
                            <Summary closeModal={() => toggleIsOpen(false)} />

                            <div className="border-top my-3">
                                <Logout
                                    color="link"
                                    className="px-0"
                                    closeModal={() => toggleIsOpen(false)}
                                >
                                    Sign Out
                                </Logout>
                            </div>
                        </ExistingVerify>
                    </LoginOrRegister>
                </ModalBody>
            </Drawer>
        </>
    );
};

export default Account;
