import React from "react";
import NavLink from "../../../Header/NavLink";
import { prependDomain } from "../../../../support/helpers";

/**
 * Displays a title and list of links for the footer.
 * @param {*} data - The list group data
 * @param function handleClick - The click handler for the nav link
 * @param string origin - The origin URL
 * @returns JSX.Element
 */
const ListGroup = ({ data, handleClick, origin }) => {
    return (
        <>
            <h4
                className="text-uppercase title"
                style={data?.label ? {} : { marginBottom: "36px" }}
            >
                {data?.label}
            </h4>
            <ul
                className="list-inline"
                style={{ columnCount: data?.columns || 1 }}
            >
                {Array.isArray(data.children) &&
                    data.children.map((item, i) => (
                        <li
                            key={`footer-${data.label.toLocaleLowerCase}-list-${i}`}
                        >
                            <NavLink
                                className="d-block text-white py-2"
                                to={{
                                    pathname: prependDomain(origin, item.link),
                                    state: { stateId: item?.stateCode },
                                }}
                                onClick={handleClick}
                                target={item?.target}
                            >
                                {item.label}
                            </NavLink>
                        </li>
                    ))}
            </ul>
        </>
    );
};

export default ListGroup;
