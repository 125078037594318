import React, { useState, useEffect, useCallback } from "react";
import { AsyncSelect } from "../ReactSelect/ReactSelect";
import PubSub from "pubsub-js";
import { useSearchbox } from "../../hooks/useSearchbox";
import { parseSearchMatches } from "./helpers";
import NoOptionsMessage from "../NoOptionsMessage";
import { useQueryParams, StringParam, NumberParam } from "use-query-params";
import { locationQueryModel } from "./SearchProvider";
import { debounce } from "../../support/helpers";
import { useNacLocation } from "../../hooks/useNacLocation";

const LocationSelect = ({
    className = "",
    menuPortalTarget,
    styles,
    onFocus,
}) => {
    const [locationQuery, setLocationQuery] = useQueryParams({
        ...locationQueryModel,
        mapView: StringParam,
        mapZoom: NumberParam,
    });
    const { search, zip, mapZoom } = locationQuery;
    const {
        state: {
            stateId: state_code = undefined,
            nicheItemId: niche_item_id = undefined,
            developmentId: developmentID = undefined,
        } = {},
    } = useNacLocation();
    const { execute: executeSearch } = useSearchbox();
    const [inputValue, setInputValue] = useState(undefined);

    useEffect(() => {
        const sub = PubSub.subscribe(
            "RESET_SEARCH",
            (subName, params = false) => {
                if (!params?.filtersOnly) setInputValue("");
            }
        );
        return () => PubSub.unsubscribe(sub);
        // eslint-disable-next-line
    }, []);

    // Setting the input value for saved searchces and if the component mounts/unmounts and there is search data
    useEffect(() => {
        if (search || zip) {
            setInputValue({ value: search || zip, label: search || zip });
        } else if (inputValue && !search && !zip) {
            setInputValue("");
        }
    }, [search, zip]);

    // we need to put the debounce in a useCallback to cache the function
    // otherwise we will redefine it on each rerender
    const loadOptions = useCallback(
        debounce((searchTerm, callback) => {
            if (searchTerm.length > 2) {
                executeSearch(searchTerm, state_code).then((response) => {
                    callback(
                        parseSearchMatches({ supplementalInfo: response })
                    );
                });
            }
        }, 250),
        [state_code]
    );

    const onInputChange = (newValue) => setInputValue(newValue);

    return (
        <AsyncSelect
            className={`${className} h-100 location-select react-select`}
            styles={{
                control: (provided) => ({
                    ...provided,
                    height: "100%",
                    width: "100%",
                }),
                ...styles,
            }}
            placeholder="Enter City or Zip"
            name="searchBox"
            value={inputValue}
            inputValue={inputValue?.label}
            onInputChange={onInputChange}
            loadOptions={loadOptions}
            onMenuClose={() => {
                if (locationQuery.search) {
                    setInputValue({
                        value: locationQuery.search,
                        label: locationQuery.search,
                    });
                }
            }}
            onKeyDown={(e) => {
                // If the user hits enter and there is no input value, reset the search
                if (e.key === "Enter" && inputValue === "") {
                    PubSub.publish("RESET_SEARCH");
                }
            }}
            components={{
                NoOptionsMessage: (props) => (
                    <NoOptionsMessage label="Enter City or Zip" {...props} />
                ),
            }}
            onChange={async (option, action) => {
                if (action.action === "clear") {
                    PubSub.publish("RESET_SEARCH");
                } else if (action.action === "select-option") {
                    const locQuery = {
                        ...locationQuery,
                        search: option.label,
                        zip: option.value?.more?.zip || option.value?.value,
                        mapView: "listings",
                    };
                    if (mapZoom) {
                        locQuery.mapZoom = undefined;
                    }
                    setLocationQuery(locQuery);
                }
                setInputValue(option);
            }}
            onFocus={onFocus}
            menuPortalTarget={menuPortalTarget}
            isClearable
        />
    );
};

export default LocationSelect;
