import React from "react";
import { FormFeedback, Label } from "reactstrap";
import { ErrorMessage } from "@hookform/error-message";
import ValidatingInput from "./ValidatingInput";

/**
 * ValidatingField component is responsible for rendering a form field with an optional label,
 * validation error message, and custom styling.
 *
 * @param {React.ReactNode} children - React children elements.
 * @param {Object} style - Custom styles for the component container.
 * @param {string} suffix - Additional content to display after the input field.
 * @param {string} className - CSS classes to apply to the component container. Defaults to "mb-3".
 * @param {boolean} hideLabel - Whether to hide the field label.
 * @param {string} labelClasses - CSS classes to apply to the label element.
 * @param {boolean} showFormField - Whether to show the form input field. Defaults to true.
 * @param {boolean} showError - Whether to show validation error messages. Defaults to true.
 * @param {Object} props - Additional props including:
 *   - {string} type - The type of the input field.
 *   - {string} name - The name of the input field.
 *   - {Object} assembly - The assembly configuration object.
 *   - {string} formFieldClasses - CSS classes for the form field.
 *   - {any} defaultValueOverride - Value to override the default field value.
 *   - {Object} formState.errors - Errors object for form validation.
 *
 * @returns {JSX.Element|null} The rendered form field or null if assembly or assemblyItem is not found.
 */
const ValidatingField = ({
    children,
    style = {},
    suffix,
    className = "mb-3",
    hideLabel,
    labelClasses = "",
    showFormField = true,
    showError = true,
    ...props
}) => {
    const {
        type,
        name,
        assembly,
        formFieldClasses = "",
        defaultValueOverride,
    } = props;

    if (!assembly) return null;
    const assemblyItem = assembly.find((item) => item.id === name);
    if (!assemblyItem) return null;
    const {
        fieldInfo: { label, default: formValue },
        requiredInfo,
    } = assemblyItem;
    const defaultValue = defaultValueOverride || formValue;
    const isRequired = requiredInfo?.required === 1;
    const {
        formState: { errors },
    } = props;

    return (
        <div className={className} style={style}>
            {!hideLabel && (
                <Label className={`mb-0 ${labelClasses}`}>
                    {label}
                    {isRequired ? "*" : ""}
                </Label>
            )}
            {showFormField ? (
                <>
                    <ValidatingInput {...props} />
                    {suffix}
                    {showError && errors && (
                        <ErrorMessage
                            errors={errors}
                            name={name}
                            render={({ message }) => (
                                <FormFeedback>{message}</FormFeedback>
                            )}
                        />
                    )}
                </>
            ) : (
                <div className={`px-2 ${formFieldClasses}`}>{defaultValue}</div>
            )}
        </div>
    );
};

export default ValidatingField;
