import React, { useEffect, useCallback, useState } from "react";
import { ModalBody, Button } from "reactstrap";
import { FaTimes, FaSearch } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import useToggle from "../../hooks/useToggle";
import Drawer from "../Drawer";
import Search from "../Search";
import styled from "styled-components";

const HeaderSearch = ({
    className,
    iconClassName,
    placeholder,
    nichetypePlural,
}) => {
    const [isSliderOpen, toggleSlider] = useToggle(false);
    const [isShowClass, toggleShowClass] = useToggle(false);
    const [isModalOpen, toggleModal] = useToggle(false);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const useModal = useMediaQuery({ maxWidth: 875 });

    const toggleSearch = useCallback(() => {
        if (!isTransitioning) {
            if (useModal) {
                toggleModal();
            } else {
                setIsTransitioning(true);
                if (isSliderOpen) {
                    toggleShowClass();
                    setTimeout(() => {
                        toggleSlider();
                        setIsTransitioning(false);
                    }, 300); // This should be the same amount of time as the search-wrapper transition
                } else {
                    toggleSlider();
                    setTimeout(() => {
                        toggleShowClass();
                        setIsTransitioning(false);
                    }, 100);
                }
            }
        }
    }, [
        isTransitioning,
        isSliderOpen,
        useModal,
        toggleModal,
        toggleSlider,
        toggleShowClass,
    ]);

    const outerClick = useCallback(
        (e) => {
            if (!e.target.closest(".search-slider")) {
                toggleSearch();
            }
        },
        [toggleSearch]
    );

    useEffect(() => {
        if (isSliderOpen) {
            document.addEventListener("click", outerClick);
        }
        return () => {
            document.removeEventListener("click", outerClick);
        };
    }, [isSliderOpen, outerClick]);

    return (
        <SearchSlider
            className={`${className} search-slider d-flex`}
            style={{ zIndex: 15 }}
        >
            {isSliderOpen && (
                <div className="slider-wrapper">
                    <div
                        className={`search-wrapper d-flex align-items-center me-1 ${
                            isShowClass ? "show" : ""
                        }`}
                    >
                        <Search
                            className="search"
                            styles={{
                                container: () => ({}),
                                control: (provided) => ({
                                    ...provided,
                                }),
                            }}
                            placeholder={placeholder}
                            nichetypePlural={nichetypePlural}
                            locationOnPage="Header Slider"
                        />
                    </div>
                </div>
            )}
            <Button
                className={`search-icon ${iconClassName}`}
                color="link"
                onClick={() => toggleSearch()}
                alt="Search"
            >
                {isSliderOpen ? <FaTimes /> : <FaSearch />}
            </Button>
            <Drawer
                isOpen={isModalOpen}
                toggle={() => toggleModal()}
                headerText={"Search"}
            >
                <ModalBody>
                    <Search callback={() => toggleModal()} />
                </ModalBody>
            </Drawer>
        </SearchSlider>
    );
};

const SearchSlider = styled.div`
    .slider-wrapper {
        position: absolute;
        right: 35px;
        top: 11px;
    }
    .search-wrapper {
        background-color: #fff;
        transition: max-width 0.3s ease, visibility 0.1s;
        max-width: 65px;
        visibility: hidden;
        .search {
            width: 320px;
            height: 100%;
            border: 0 none;
            background: transparent;
            box-shadow: none;
        }
        &.show {
            visibility: visible;
            max-width: 400px;
        }
    }
`;

export default HeaderSearch;
